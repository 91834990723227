
<template>
    <div class="box">
        <el-col :xs="6" :sm="6" :md="5" :lg="5" class="tree-left" style="background: #fff;">
            <div class="box-left">
                <div class="taplist">
                    <div :class="[tapindex==index?'active':'li']"   @click="tapclick(index)" v-for="(item,index) in taplist" :key="index">{{ item }}</div>
                </div>
            </div>
        </el-col>
        <el-col :xs="18" :sm="18" :md="19" :lg="19" class="tree-right">
            <div class="box-right">
                <div class="de-one">
                    <div class="title">基本信息</div>
                    <div class="de-tit">
                        <div>消息标题</div>
                        <input type="text" name="" id="">
                    </div>
                    <div class="de-tit">
                        <div>消息标题</div>
                        <textarea name="" id="" placeholder="请输入方案描述"></textarea>
                    </div>
                </div>
                <div class="de-one">
                    <div class="title">
                        <div>可见代理商</div>
                        <div>
                            <el-button type="primary" plain @click="dialogVisible = true">
                             <i class="el-icon-plus" ></i>&nbsp;&nbsp;新增</el-button>
                        </div>
                    </div>
                    <el-table
                :data="newdata"
                style="width: 100%;"
                height="100%"
                min-height="400px"
                border
                :header-cell-style="{
                  'background-color': '#f2f2f2',
                  'font-size': '16px',
                  'text-align': 'center',
                  border: '1px solid #ddd',
                }"
              >
                  <el-table-column
          prop="agent_name"
          label="代理商名称"
          
          align="center"
        >
                  </el-table-column>
           
                  <el-table-column
                    label="操作"
                    width="250"
                    align="center"
                  >
                  <template slot-scope="scope">
                  <el-button  type="text" @click="look(scope.row.id)">查看</el-button>
                  </template>
                  </el-table-column>
          </el-table>
                </div>
                <div class="de-one">
                    <div class="title">
                        <div>可见人员</div>
                        <div>
                            <el-button type="primary" plain @click="adduser">
                             <i class="el-icon-plus" ></i>&nbsp;&nbsp;新增</el-button>
                        </div>
                    </div>
                    <el-table
                :data="newsdata"
                style="width: 100%;"
                height="100%"
                max-height="400"
                border
                :header-cell-style="{
                  'background-color': '#f2f2f2',
                  'font-size': '16px',
                  'text-align': 'center',
                  border: '1px solid #ddd',
                }"
              >
                  <el-table-column
          prop="name"
          label="所属代理商"
          
          align="center"
        >
                  </el-table-column>
                  <el-table-column
          prop="name"
          label="代理商名称"
          
          align="center"
        >
                  </el-table-column> <el-table-column
          prop="name"
          label="人员名称"
          
          align="center"
        >
                  </el-table-column><el-table-column
          prop="name"
          label="联系方式"
          
          align="center"
        >
                  </el-table-column>
                  
                  <el-table-column
                    label="操作"
                    width="250"
                    align="center"
                  >
                  <template slot-scope="scope">
                  <el-button  type="text" @click="look(scope.row.id)">查看</el-button>
                  </template>
                  </el-table-column>
          </el-table>
                </div>
                <el-button style="width: 150px;height: 50px;margin-left: 45%;margin-top: 40px;" type="primary" size="medium">确 定</el-button>

            </div>
            
           
        </el-col>
        <el-dialog
        title="请选择代理商"
        :visible.sync="dialogVisible"
        width="40%"
        
        :before-close="handleClose">
            <div class="dal">
                <div class="daleft">
                <el-table
                :data="dlsdata"
                style="width: 100%"
                height="100%"
                max-height="400"
                @selection-change="handleSelectionChange"
                border
                :header-cell-style="{
                  'background-color': '#f2f2f2',
                  'font-size': '16px',
                  'text-align': 'center',
                  border: '1px solid #ddd',
                }"
              >
              <el-table-column type="selection" width="50"> </el-table-column>
                  <el-table-column
          prop="agent_name"
          label="代理商名称"
          
          align="center"
        >
                  </el-table-column>
           
                 
          </el-table>
            </div>
            <div class="daright">
                <div class="dar-top">
                    <div class="yxz">已选择</div>
                    <el-button type="primary" plain @click="open()">
                     <i class="el-icon-delete"></i>&nbsp;&nbsp;清空</el-button
                    >
                </div>
                <div class="dllist">
                    <div v-for="(item,i) in dlslist" :key="i">{{ item.agent_name}}</div>
                </div>
            </div>
            </div>
            <span slot="footer" class="dialog-footer" style="text-align: center;">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        
       
        </el-dialog>
        <el-dialog
        title="请选择区域"
        :visible.sync="dialogVisibletwo"
        width="40%"
        :before-close="handleClose">
            <div class="diatwo" v-for="(item,i) in  rydata" :key="i">
                <div class="left">
                    <div class="tit">请选择代理商:</div>
                    <div class="radio">
                        <el-radio-group v-model="radio">
                          <el-radio :label="3">备选项</el-radio>
                          <el-radio :label="6">备选项</el-radio>
                          <el-radio :label="9">备选项</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="right">
                    <div class="tit">请选择人员:</div>
                    <div class="radio">
                        <el-radio-group v-model="radio2">
                          <el-radio :label="3">备选项</el-radio>
                          <el-radio :label="6">备选项</el-radio>
                          <el-radio :label="9">备选项</el-radio>
                        </el-radio-group>
                    </div>
                </div>


            </div>
            
            <span slot="footer" class="dialog-footer" style="text-align: center;">
              <el-button @click="dialogVisibletwo = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisibletwo = false">确 定</el-button>
            </span>
        
       
        </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
        tapclick(index){
            this.tapindex = index
        },
        getdls(){
            this.$http.post("noticemessage/queryListAsString",{}).then(res => {
               this.dlsdata = res.data.data;
            });
            
        },
        handleSelectionChange(val){
            this.dlslist=val
            this.newdata=val
        },
        adduser(){
            console.log(1);
            this.dialogVisibletwo = true
            const userli=this.newdata.map(item=>{
                return item.agent_code
            })
            console.log('newdata',this.newdata,userli);
            this.$http.post("noticemessage/queryListAsStringByCode",userli).then(res => {
               this.rydata = res.data.data;
            });
        }
    },
    
    data() {
      return {
        taplist:["基本信息","可见代理商","可见人员"],
        tapindex:0,
        queryInfo: {
          pageSize: 100,
          pageCount: 1,
          currPage: 1,
          condition: {}
        },
        newdata:[],
        newsdata:[{
             name:"www",
             id:1131231,
             title:"申请客户转移",
             type:"其他",
             content:"大地啊大奶哦对面拿破打磨，u安徽的和你哦对",
             ind:"未读",
             time:"2010-09-09 18:00:00"
        }],
        dlsdata:[],
        dialogVisible:false,
        dialogVisibletwo:false,
        radio: 3,
        radio2: 3,
        dlsdas:{
            angCode:""
        },
        dlslist:[],
        rydata:[]
     
      };
    },
    created(){
      this.getdls()
    }
  };
  </script>
  
  <style lang="less" >
  .box {
   
    display: flex;
    .box-left {
        background-color: #ffffff;
        .taplist {
           
            margin-top: 20px;
            cursor: pointer;
            .li {
                width: 100%;
                height: 60px;
                color: #333333;
                line-height: 60px;
                box-sizing: border-box;
                padding-left: 30px;
                background-color: #f2f2f2;
                
            }
            .active {
                width: 100%;
                height: 60px;
                color: #000;
                font-weight: 700;
                line-height: 60px;
                box-sizing: border-box;
                padding-left: 30px;
                background-color: #b2e4fb;
            }
        }
    }
    .box-right {
        background-color: #ffffff;
        padding-top: 30px;
        padding-left: 20px;
        height: 100vh;
        .de-one {
            .title {
                color:  #333333;
                font-size: 20px;
                border-bottom: 1px solid #d7d7d7;
                line-height: 40px;
                font-weight: 700;
                display: flex;
                justify-content: space-between;
                margin-top: 30px;
            }
            .de-tit {
                display: flex;
                font-size: 20px;
                box-sizing: border-box;
                padding-left: 30px;
                padding-top: 20px;
                input {
                    width: 490px;
                    height: 40px;
                    padding: 2px 2px 2px 2px;
                    border-radius: 5px;
                    border: 1px solid #d7d7d7;
                    background-color: #ffffff;
                    box-sizing: border-box;
                    margin-left: 10px;
                }
                textarea {
                    width: 1114px;
                    height: 150px;
                    padding: 2px 2px 2px 2px;
                    border-radius: 5px;
                    border: 1px solid #d7d7d7;
                    background-color: #ffffff;
                    box-sizing: border-box;
                    margin-left: 10px;
                }
            }
        }
    }
    .dal {
        display: flex;
       
        .daleft {
            width: 60%;
        }
        .daright {
           flex: 1;
            .dar-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #70b6ff;
                border-top: 1px solid #d7d7d7;
                margin-top: 15px;
            }
            .dllist {
                div {
                    width: 100%;
                height: 45px;
                border: 1px solid rgba(129, 211, 248, 0.45);
                background-color: rgba(129, 211, 248, 0.45);
                color: #02a7f0;
                line-height: 45px;
                box-sizing: border-box;
                padding-left: 15px;
                }
                
            }
        }

    }
    .diatwo {
        display: flex;
        border-top: 1px solid rgba(129, 211, 248, 0.45);
        height: 100%;
        .tit {
                color: #02a7f0;
                margin: 20px;
            }
        .left {
            flex: 1;
            border-right: 1px solid rgba(129, 211, 248, 0.45);
            .radio {
                margin-left: 30px;
                width: 20%;
            }
        }
        .right {
            flex: 1;
            .radio {
                margin-left: 30px;
                width: 20%;
            }
        }
    }
    
  }
  
  </style>